.applications {
  display: grid;
  position: relative;
  grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
  gap: 16px;
  padding-top: 16px;
  padding-bottom: 4px;
  min-height: 120px;
}

.new-application {
  user-select: none;
  cursor: pointer;
  font-size: 14px;
  border-radius: 4px;
  box-shadow: rgba(55, 53, 47, 0.09) 0px 0px 0px 1px inset;
  min-height: 40px;
  min-height: 120px;
  align-items: center; /* Centraliza verticalmente o conteúdo */
  justify-content: center; /* Centraliza horizontalmente o conteúdo */
}

.new-application > p {
  margin: 0; /* Remova as margens padrão para evitar espaçamento extra */
}

/* Aplica display flex apenas quando a altura for maior que 40px */
.new-application {
  display: flex;
  flex-direction: column;
}

.new-application:hover {
  background-color: #f9f9f9;
}
