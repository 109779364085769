body { 
  background-color: #f0f0f0; 
  font-family: 'Arial', sans-serif; 
  
  justify-content: center; 
  align-items: center; 
  height: 100vh; 
  margin: 0; 
  max-width: 90%;
  margin: 0 auto;
  padding: 20px 0;
  } 
  
  .container { 
  width: 100%; 
  text-align: center; 
  background-color: #fff; 
  border-radius: 10px; 
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); 
  padding: 20px; 
  } 
  
  .heading { 
  font-size: 28px; 
  margin-bottom: 20px; 
  color: #333; 
  } 
  
  .subHeading { 
  font-size: 20px; 
  margin-bottom: 15px; 
  display: flex; 
  justify-content: space-between; 
  align-items: center; 
  } 
  
  #alert { 
  color: green; 
  margin-left: 5px; 
  } 
  
  .input { 
  width: 90%; 
  padding: 12px; 
  border: 2px solid #ccc; 
  border-radius: 5px; 
  margin: 10px 0; 
  font-size: 16px; 
  } 
  
  .table { 
  margin: 2rem 0; 
  } 
  
  .passwordItem { 
  background-color: #f9f9f9; 
  border: 1px solid #ccc; 
  border-radius: 10px; 
  padding: 15px; 
  margin-bottom: 15px; 
  } 
  
  .listItem { 
  display: flex; 
  justify-content: space-between; 
  align-items: center; 
  margin-bottom: 10px; 
  margin-right: 10px; 
  } 
  .listValue { 
  margin-right: 15px; 
  } 
  .listLabel { 
  font-weight: bold; 
  width: 100px; 
  padding: 12px; 
  font-size: 18px; 
  } 
  
  .showPasswordIcon { 
  cursor: pointer; 
  margin-left: 16px; 
  color: #007bff; 
  font-size: 20px; 
  transition: color 0.3s; 
  } 
  
  .showPasswordIcon:hover { 
  color: #0056b3; 
  } 
  
  .copyIcon, 
  editIcon, 
  deleteIcon { 
  cursor: pointer; 
  color: #555; 
  font-size: 20px; 
  transition: color 0.3s; 
  margin-left: 10px; 
  } 
  
  .copyIcon:hover, 
  editIcon:hover, 
  deleteIcon:hover { 
  color: #007bff; 
  } 
  
  .submitButton { 
  background: #007bff; 
  color: #fff; 
  padding: 12px 24px; 
  text-align: center; 
  border: none; 
  border-radius: 5px; 
  cursor: pointer; 
  margin: 12px 0; 
  font-weight: bold; 
  font-size: 18px; 
  } 
  
  .noData { 
  text-align: center; 
  color: #777; 
  margin-top: 20px; 
  font-size: 18px; 
  } 
  
#root {
  padding:20px 0;
}

input,button {
  padding:3px;
  margin-right: 10px;;
  margin-bottom: 10px;;
}