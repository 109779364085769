.app-table-container {
    margin: 20px;
  }
  
  .app-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
  }
  
  .app-table th,
  .app-table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
  }
  
  .app-table th {
    background-color: #f2f2f2;
  }
  
  .app-table tbody tr:hover {
    background-color: #f5f5f5;
  }
  
  .app-table button {
    margin-right: 5px;
    cursor: pointer;
  }